<!--资金需求详情页-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/resource'}">资源集市</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/enterpriseResource'}">企业资源</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/enterpriseNeedZJList'}">资金需求</el-breadcrumb-item>
                <el-breadcrumb-item >资金需求详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="e-h2 w-1400">
            {{pageData.title}}
        </div>
        <div class="p-list w-1400">
            <div class="floor1 clearfix">
                <el-row :gutter="40">
                    <el-col :span="11">
                        <div class="f1-pic fl">
                            <el-image style="width:100%;heihgt:100%" fit="cover" :src="baseUrl+pageData.photoUrls.split(',')[0]"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="13">
                        <div style="padding:10px 20px">
                            <div class="floor foorl1 fl">
                                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 资金需求类型</div>
                                <div class="floor-text value1 f1-r" v-html="pageData.needType"></div>
                            </div>
                            <div class="floor foorl1 fl">
                                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 资金需求数目</div>
                                <div class="floor-text value2 f1-r" v-html="'￥'+pageData.funds+'万'"></div>
                            </div>
                        </div>
                        
                    </el-col>
                </el-row>
            </div>
            <div class="floor2 mt-10">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 项目详情</div>  
                <div class="floor-text licheng"  >
                    {{pageData.projectInfo}}
                    
                </div>  
            </div> 
            <div class="floor3 mt-10">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 企业简介</div>  
                <div class="floor-text ">{{pageData.enterpriseInfo}}</div>  
            </div> 
        </div>
    </div>
</template>
<script>
import {GetOneEnterpriseNeedzj,GetEnterpriseNeedzj} from '@/api/ziyuanjishi'
import {regHtml} from '@/utils/reg'
export default {
    data(){
        return{
             pageData:{
                 title:"",
             },
              params: {
                currentPage: 1,
                pageSize: 10,
            },
        }
    },
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
    },
    methods:{
        getDetail(){
            GetOneEnterpriseNeedzj({id:this.$route.query.id}).then(res=>{
                this.pageData = res.data.data;
            })
        },
        // getEnterPriseLandList(){
        //     GetEnterpriseNeedzj(this.enterpriselandParams).then(res=>{
        //         this.enterpriselandData=res.data.data.list;
        //     })
        // }
     },
    mounted(){
        this.getDetail();
        // this.getEnterPriseLandList();
    }
}
</script>
<style scoped>
.value1.f1-r{
    background: #aa7439;
}
.value2.f1-r{
    color: #2f2f2f;
    padding: 10px 20px;
    font-weight: bold;
    font-family: '黑体';
    font-size: 20px;
    background-image: linear-gradient(to bottom,rgba(133, 167, 226,.6) 0%,rgba(133, 167, 226,.3) 50%, rgba(133, 167, 226,1));
}
.f4-title{
    font-size: 18px;
    text-align: center;
    line-height: 32px;
}
.f4-pic{
    /* width: 280px; */
    height: 190px;
    overflow:hidden;
}
.floor-text.licheng{
    padding-left: 30px;
    text-indent: 0px;
}
.foorl1{
    width: calc(100% - 170px);
    height: 240px;
}
.floor-text{
    font-size: 16px;
    line-height: 30px;
    margin-left: 50px;
}
.f1-pic{
    width: 600px;
    height: 500px;
    overflow: hidden;
}
.f1-r{
    padding:6px 20px;
    color: #fff;
    display: inline-block;
    border-radius: 8px;
    margin-top: 16px;
}
.e-h2{
    font-size: 30px;
    font-weight: 60px;
    text-align: center;
}
.floor{
    padding: 20px;
}
.floor-h3 i{
    font-size: 22px;
}
.floor-h3{
    line-height: 42px;
    font-size: 22px;
    color: #0e6ac1;
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>